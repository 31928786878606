import React, { memo } from 'react'
import { Header, Message, Icon } from 'semantic-ui-react'
import zh from './qr-code-module-zh.json'
import en from './qr-code-module-en.json'

type VerifiedMessageProps = {}

export const VerifiedMessage = memo<VerifiedMessageProps>(() => {
  return (
    <Message color="yellow">
      <Header icon>
        <Icon color="yellow" size="huge" name="warning circle" />
      </Header>
      {zh.instruction}
      <br />
      {en.instruction}
    </Message>
  )
})
