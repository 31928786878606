import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import {
  Container,
  Header,
  Image,
  Message,
  Placeholder,
} from 'semantic-ui-react'
import styled from 'styled-components'
import { create } from 'apisauce'
import mtrLogo from '../../assets/mtr-logo-medium.png'
import { VisitorRegistrationForm } from '../../components/visitor-registration-form'
import { FormObjectType } from '../../components/visitor-registration-form/api-object.types'
import en from '../../components/visitor-registration-form/form-constant-en.json'
import zh from '../../components/visitor-registration-form/form-constant-zh.json'
import { QRCodeModule } from '../../components/qr-code-module'

export interface LocationsType {
  code: string
  displayName: string
  list: Location[]
  customText?: string
}
interface Location {
  code: string
  displayName: string
}

const Root = styled(Container)`
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
  width: 100wh;
`

export const HomePage: React.FC = () => {
  const [formReferenceNumber, setFormReferenceNumber] = useState('')
  const [displayFormValue, setDisplayFormValue] = useState<FormObjectType>()
  const { id } = useParams()
  const [whitelistId, setWhitelistId] = useState(undefined)

  useEffect(() => {
    if (id == null) return
    if (id.length === 36) {
      setWhitelistId(id)
    }
  }, [id])

  const [locations, setLocations] = useState<LocationsType[]>()

  const [api] = useState(
    create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
    }),
  )

  useEffect(() => {
    const getLocationsList = async () => {
      const language = window.navigator.language.includes('zh') ? 'chi' : 'eng'
      const response = await api.get(
        `/visitor-form-records/locations/${language}`,
      )

      if (response.ok) {
        const responseData = response.data as LocationsType[]
        setLocations(responseData)
      }
    }
    getLocationsList()
  }, [api])

  const { data, isLoading, error } = useQuery(
    whitelistId,
    async (): Promise<any> => {
      if (whitelistId == null) return Promise.resolve()

      return axios.get<{
        is_enabled: boolean
        is_effective: boolean
        effective_start_date: string
        effective_end_date: string
      }>(`${process.env.REACT_APP_API_BASE_URL}/whitelists/${whitelistId}`)
    },
    { retry: 0 },
  )

  const isNotPermitted =
    (data && !data?.data.is_enabled) ||
    // @ts-ignore
    error?.response?.status === 404

  const isExpired = !(data?.data.is_effective ?? false) && data != null

  if (isLoading) {
    return (
      <Root>
        <Image src={mtrLogo} floated="right" size="small" />
        <Header textAlign="center" size="large">
          <Placeholder fluid>
            <Placeholder.Header>
              <Placeholder.Line length="short" />
            </Placeholder.Header>
          </Placeholder>
        </Header>
        <Placeholder>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      </Root>
    )
  }

  if (error || isNotPermitted || isExpired) {
    return (
      <Root>
        <Image src={mtrLogo} floated="right" size="small" />
        <Message
          error={!isNotPermitted && !isExpired && error != null}
          warning={isNotPermitted}
          size="big"
          color={isExpired ? 'orange' : undefined}
          icon={
            isExpired
              ? 'calendar times outline'
              : isNotPermitted
              ? 'ban'
              : 'warning sign'
          }
          header={
            isExpired
              ? `${zh.expired} ${en.expired}`
              : isNotPermitted
              ? `${zh.forbidTitle} ${en.forbidTitle}`
              : `${zh.submissionFailed} ${en.submissionFailed}`
          }
          content={
            isExpired ? (
              <>
                {zh.expiredMessage
                  .replace(
                    '{{start}}',
                    moment(data?.data.effective_start_date).format(
                      'D MMM yyyy',
                    ),
                  )
                  .replace(
                    '{{end}}',
                    moment(data?.data.effective_end_date).format('D MMM yyyy'),
                  )}
                <br />
                {en.expiredMessage
                  .replace(
                    '{{start}}',
                    moment(data?.data.effective_start_date).format(
                      'D MMM yyyy',
                    ),
                  )
                  .replace(
                    '{{end}}',
                    moment(data?.data.effective_end_date).format('D MMM yyyy'),
                  )}
              </>
            ) : isNotPermitted ? (
              <>
                {zh.forbidMessage}
                <br />
                {en.forbidMessage}
              </>
            ) : (
              <>
                {zh.submissionFailedDetail}
                <br />
                {en.submissionFailedDetail}
                <br />
                {`(${error})`}
              </>
            )
          }
        />
      </Root>
    )
  }

  const clearAll = () => {
    setFormReferenceNumber('')
    setDisplayFormValue(undefined)
  }
  return formReferenceNumber === '' &&
    displayFormValue == null &&
    locations != null ? (
    <VisitorRegistrationForm
      setReferenceNumber={setFormReferenceNumber}
      setValue={setDisplayFormValue}
      locations={locations}
    />
  ) : (
    <QRCodeModule
      formValue={displayFormValue}
      referenceNumber={formReferenceNumber}
      clearReferenceNumber={clearAll}
    />
  )
}
