import React, { useMemo } from 'react'
import QRCode from 'qrcode.react'
import styled from 'styled-components'
import {
  Container,
  Button,
  Message,
  Header,
  Image,
  Icon,
} from 'semantic-ui-react'
import mtrLogo from '../../assets/mtr-logo-medium.png'
import zh from './qr-code-module-zh.json'
import en from './qr-code-module-en.json'
import './qr-code-module.scss'
import { FormObjectType } from '../visitor-registration-form/api-object.types'
import { VerifiedMessage } from './verfied-message'

const Root = styled(Container)`
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
  width: 100wh;
`

const ReferenceNumberTitle = styled.p``

const ReferenceNumber = styled.p`
  font-size: 1.75em;
  font-weight: bold;
`

interface QrcodeModuleProps {
  formValue?: FormObjectType
  referenceNumber: string
  clearReferenceNumber: () => void
}

export const QRCodeModule: React.FC<QrcodeModuleProps> = ({
  formValue,
  referenceNumber,
  clearReferenceNumber,
}) => {
  const recordRejected = useMemo(() => formValue?.is_permitted !== true, [
    formValue,
  ])

  return (
    <Root>
      <Image src={mtrLogo} floated="right" size="small" />
      <Header textAlign="left" size="large">
        <Header.Content>
          {zh.title}
          <br />
          {en.title}
        </Header.Content>
      </Header>

      {formValue != null && <VerifiedMessage />}

      {recordRejected && formValue?.reject_remark?.length && (
        <Message color="blue">{formValue.reject_remark}</Message>
      )}

      <Container className="qrCodeContainer" textAlign="center">
        <QRCode value={referenceNumber} size={256} />
      </Container>

      <Container textAlign="center">
        <ReferenceNumberTitle>{`${zh.referenceNumber} ${en.referenceNumber}`}</ReferenceNumberTitle>
        <ReferenceNumber>{referenceNumber}</ReferenceNumber>
      </Container>

      <Container className="buttonContainer" textAlign="center">
        <Button animated onClick={clearReferenceNumber}>
          <Button.Content visible>{`${zh.back} ${en.back}`}</Button.Content>
          <Button.Content hidden>
            <Icon name="arrow left" />
          </Button.Content>
        </Button>
      </Container>
    </Root>
  )
}
