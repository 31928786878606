import React, { ReactElement } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { HomePage } from './pages/home'

const AppRouter = (): ReactElement => {
  return (
    <Router>
      <Switch>
        <Route path="/:id?" component={HomePage} />
        <Route path="/" component={HomePage} />
      </Switch>
    </Router>
  )
}

export default AppRouter
