import React, { FunctionComponent } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import 'semantic-ui-css/semantic.min.css'
import { ReactQueryConfigProvider } from 'react-query'
import AppRouter from './router'

const queryConfig = { queries: { refetchOnWindowFocus: false } }

const App: FunctionComponent = () => (
  <ReactQueryConfigProvider config={queryConfig}>
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
      <AppRouter />
    </GoogleReCaptchaProvider>
  </ReactQueryConfigProvider>
)

export default App
