/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { create } from 'apisauce'
import moment from 'moment'
import React, { useCallback, useState, useMemo, useEffect } from 'react'
import {
  Button,
  Checkbox,
  Container,
  Dimmer,
  Form,
  Grid,
  Header,
  Image,
  Label,
  Message,
} from 'semantic-ui-react'
import styled from 'styled-components'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import { useParams } from 'react-router-dom'
import { LocationsType } from '../../pages/home/home-page'
import mtrLogo from '../../assets/mtr-logo-medium.png'
import { FormObjectType, VisitorObjectType } from './api-object.types'
import en from './form-constant-en.json'
import zh from './form-constant-zh.json'
import './visitor-registration-form.scss'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css'

interface ReferenceNumberProps {
  setValue: (formValue: FormObjectType) => void
  setReferenceNumber: (referenceNumber: string) => void
  locations: LocationsType[]
}

type FormObject = FormObjectType & {
  visitors: VisitorObjectType[]
}

const locationOtherString = 'others'

const Root = styled(Container)`
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
  width: 100wh;
`
const HeaderContainer = styled(Container)`
  height: 35px;
`
const ConfidentialTag = styled(Label)`
  float: left;
`

const ErrorMessage = styled(Message)`
  text-align: left;
`

const ConfirmDeclaration = styled(Form.Field)`
  padding-top: 26px;
`

const ConfirmDeclarationLabel = styled.p`
  margin-left: 26px;
`

const ConfirmDeclarationLabelText = styled.p`
  margin-bottom: 0px;
`

const LocationDropDown = styled(Form.Dropdown)`
  @media only screen and (min-width: 992px) {
    padding-top: 20px;
  }
`

const StyledSemanticDatepicker = styled(SemanticDatepicker)`
  width: 100%;
`

type FormValue = {
  companyName: string
  purposeOfVisit: string
  dateOfVisiting: string | null
  location: string
  visitingStaffName: string
  numberOfVisitors: string
  visitors: {
    name: string
    contactNumber: string
  }[]
}

export const VisitorRegistrationForm: React.FC<ReferenceNumberProps> = ({
  setValue,
  setReferenceNumber,
  locations,
}) => {
  const { id } = useParams<{ id: string | undefined }>()

  const { executeRecaptcha } = useGoogleReCaptcha()
  const [locationFilter, setLocationFilter] = useState('')

  const locationTypeOptions = useMemo(
    () =>
      locations
        .map((it) => ({
          name: it.code,
          value: it.code,
          text: it.displayName,
        }))
        .flat(),
    [locations],
  )

  const locationOptions = useMemo(
    () =>
      locationFilter !== ''
        ? locations
            .filter((it) =>
              locationFilter != null && locationFilter !== ''
                ? it.code === locationFilter && it?.list != null
                : it.list != null,
            )
            .map((it) =>
              it.list.map((location) => ({
                name: location.code,
                value: location.displayName,
                text: location.displayName,
              })),
            )
            .flat()
        : [],
    [locationFilter, locations],
  )

  const availableLocationCode = useMemo(
    () =>
      locations
        .map((category) =>
          category.list.map((it) => ({ name: it.code, value: it.displayName })),
        )
        .flat(),
    [locations],
  )

  const locationOtherStatePlaceholder = useMemo(() => {
    const otherLocation = locations.filter((it) => it.customText != null)
    return otherLocation[0].customText
  }, [locations])

  const [formValue, setFormValue] = useState<FormValue>({
    companyName: '',
    purposeOfVisit: '',
    dateOfVisiting: null,
    location: '',
    visitingStaffName: '',
    numberOfVisitors: '1',
    visitors: [
      {
        name: '',
        contactNumber: '',
      },
    ],
  })
  const [informationDeclared, setInformationDeclaration] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [locationState, setLocationState] = useState('')
  const [locationOtherState, setLocationOtherState] = useState('')
  const [isLocationEmpty, setIsLocationEmpty] = useState(false)
  const [maxVisitingDaysDiff, setMaxVisitingDaysDiff] = useState(0)
  const [hasOtherPurpose, setHasOtherPurpose] = useState(false)

  const locationString = useMemo(
    () =>
      locationFilter === locationOtherString && locationOtherState.length > 0
        ? locationOtherState
        : locationState,
    [locationState, locationFilter, locationOtherState],
  )

  const [api] = useState(
    create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
    }),
  )

  const [errorMessage, setErrorMessage] = useState<{
    status: number
    data: string
  }>()

  const submitForm = useCallback(() => {
    setIsSubmitting(true)

    if (locationString === '') {
      setIsLocationEmpty(true)
      setIsSubmitting(false)
      window.scrollTo({ top: 0, behavior: 'smooth' })
      return
    }

    const postingForm = async (): Promise<void> => {
      if (executeRecaptcha == null) {
        setErrorMessage({
          status: -3,
          data: 'ReCAPTCHA unavailable',
        })
        setIsSubmitting(false)
        return
      }

      let recaptchaToken: string | undefined

      try {
        recaptchaToken = await executeRecaptcha()
      } catch (error) {
        setErrorMessage({
          status: -2,
          data: 'ReCAPTCHA failed',
        })
        setIsSubmitting(false)
        return
      }

      if (recaptchaToken == null) {
        setErrorMessage({
          status: -1,
          data: 'ReCAPTCHA empty',
        })
        setIsSubmitting(false)
        return
      }

      const convertedNumberOfVisitors = Number(formValue.numberOfVisitors)

      if (
        !Number.isInteger(convertedNumberOfVisitors) ||
        convertedNumberOfVisitors < 1
      ) {
        // eslint-disable-next-line no-alert
        alert(`${zh.incorrectNumberOfVisitor} ${en.incorrectNumberOfVisitor}`)
        setIsSubmitting(false)
        return
      }

      const locationCode =
        locationOptions.filter((it) => it.value === locationString)[0]?.name ??
        availableLocationCode.filter((it) => it.value === locationString)[0]
          ?.name ??
        locationOtherString

      const visitors: VisitorObjectType[] = formValue.visitors.map((it) => ({
        name: it.name,
        contact_number: it.contactNumber,
      }))

      const formObject = {
        name_of_company: formValue.companyName,
        purpose_of_visit: formValue.purposeOfVisit,
        date_of_visit: formValue.dateOfVisiting,
        location: locationString,
        location_code: locationCode,
        name_of_visiting_staff: formValue.visitingStaffName,
        number_of_registered_visitors: convertedNumberOfVisitors,
        recaptcha: recaptchaToken,
        visitors,
      } as FormObject

      const response = await api.post('/visitor-form-records', {
        ...formObject,
      })
      if (response.ok) {
        const responseObject = response.data as FormObjectType
        const referenceNumber = responseObject.reference_number
        if (referenceNumber == null) {
          setErrorMessage({
            status: -30,
            data: 'Ref null',
          })
        } else {
          setReferenceNumber(referenceNumber ?? '')
          setValue(formObject ?? undefined)
        }
      } else {
        setErrorMessage({
          status: response.status ?? 0,
          data: response.data as string,
        })
      }
      setIsSubmitting(false)
    }

    postingForm()
  }, [
    locationString,
    executeRecaptcha,
    locationOptions,
    availableLocationCode,
    formValue.visitors,
    formValue.companyName,
    formValue.purposeOfVisit,
    formValue.dateOfVisiting,
    formValue.visitingStaffName,
    formValue.numberOfVisitors,
    api,
    setReferenceNumber,
    setValue,
  ])

  const locationDropdownOnChange = useCallback((event, { value }): void => {
    setLocationState(value)
    setIsLocationEmpty(false)
  }, [])

  const locationTypeDropdownOnChange = useCallback((event, { value }): void => {
    setLocationState('')
    setLocationFilter(value)
  }, [])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const GettingDiff = async () => {
      const response = await api.get(
        '/visitor-form-records/max-visit-days-diff',
      )
      if (response.ok) {
        const day = response.data as number
        setMaxVisitingDaysDiff(day)
      } else {
        setMaxVisitingDaysDiff(0)
      }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const SetDefaultLocation = async () => {
      if (id == null) return
      const locationKey = availableLocationCode.find((it) => it.name === id)
        ?.value
      if (locationKey == null) return

      const locationFiterValue = locations.find(
        (it) =>
          it.list.find((place) => place.displayName === locationKey) != null,
      )?.code

      if (locationFiterValue == null) return

      setLocationFilter(locationFiterValue)
      setLocationState(locationKey)
      setIsLocationEmpty(false)
    }
    GettingDiff()
    SetDefaultLocation()
  }, [api, availableLocationCode, id, locations])

  return (
    <Root>
      <HeaderContainer>
        <Image src={mtrLogo} floated="right" size="small" />
        <ConfidentialTag basic color="red" size="big">
          {en.confidential}
        </ConfidentialTag>
      </HeaderContainer>
      <Header textAlign="center" size="large">
        <Header.Content>
          {zh.formTitle}
          <br />
          {en.formTitle}
        </Header.Content>
      </Header>

      <Form onSubmit={submitForm}>
        <Grid>
          <Grid.Column mobile={16} computer={16}>
            <Form.Field required>
              <label>{`${zh.purposeOfVisit} ${en.purposeOfVisit}`}</label>
            </Form.Field>
            <Form.Group>
              <Form.Field>
                <Form.Radio
                  name="radioGroup"
                  label={`${zh.purposeOfVisitOptions[0]} ${en.purposeOfVisitOptions[0]}`}
                  value="meeting"
                  checked={formValue.purposeOfVisit === 'meeting'}
                  onChange={(): void => {
                    setHasOtherPurpose(false)
                    setFormValue({
                      ...formValue,
                      purposeOfVisit: 'meeting',
                    })
                  }}
                />
              </Form.Field>
              <Form.Field>
                <Form.Radio
                  name="radioGroup"
                  label={`${zh.purposeOfVisitOptions[1]} ${en.purposeOfVisitOptions[1]}`}
                  value="visit"
                  checked={formValue.purposeOfVisit === 'visit'}
                  onChange={(): void => {
                    setHasOtherPurpose(false)
                    setFormValue({
                      ...formValue,
                      purposeOfVisit: 'visit',
                    })
                  }}
                />
              </Form.Field>
              <Form.Field>
                <Form.Radio
                  name="radioGroup"
                  label={`${zh.purposeOfVisitOptions[2]} ${en.purposeOfVisitOptions[2]}`}
                  value="teaching"
                  checked={formValue.purposeOfVisit === 'teaching'}
                  onChange={(): void => {
                    setHasOtherPurpose(false)
                    setFormValue({
                      ...formValue,
                      purposeOfVisit: 'teaching',
                    })
                  }}
                />
              </Form.Field>
              <Form.Field>
                <Form.Radio
                  name="radioGroup"
                  label={`${zh.purposeOfVisitOptions[3]} ${en.purposeOfVisitOptions[3]}`}
                  value="interview"
                  checked={formValue.purposeOfVisit === 'interview'}
                  onChange={(): void => {
                    setHasOtherPurpose(false)
                    setFormValue({
                      ...formValue,
                      purposeOfVisit: 'interview',
                    })
                  }}
                />
              </Form.Field>
              <Form.Field>
                <Form.Radio
                  name="radioGroup"
                  label={`${zh.purposeOfVisitOptions[4]} ${en.purposeOfVisitOptions[4]}`}
                  value="others"
                  checked={hasOtherPurpose}
                  onChange={(): void => {
                    setFormValue({
                      ...formValue,
                      purposeOfVisit: '',
                    })
                    setHasOtherPurpose(true)
                  }}
                />
              </Form.Field>
            </Form.Group>
          </Grid.Column>
          {hasOtherPurpose && (
            <Grid.Column mobile={16} computer={16}>
              <Form.Field>
                <Form.Input
                  label={`${zh.enterPurpose} ${en.enterPurpose}`}
                  name="others"
                  placeholder={`${zh.enterPurpose} ${en.enterPurpose}`}
                  onChange={(event): void =>
                    setFormValue({
                      ...formValue,
                      purposeOfVisit: event.target.value,
                    })
                  }
                  required
                />
              </Form.Field>
            </Grid.Column>
          )}
        </Grid>
        <Grid>
          <Grid.Column mobile={16} computer={16}>
            <Form.Input
              name="companyName"
              placeholder={`${zh.companyName} ${en.companyName}`}
              label={`${zh.companyName} ${en.companyName} (${zh.companyNameRemarks} ${en.companyNameRemarks})`}
              onChange={(event): void =>
                setFormValue({
                  ...formValue,
                  companyName: event.target.value,
                })
              }
              required
            />
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column mobile={8} computer={8}>
            <Form.Input
              name="vistorName"
              placeholder={`${zh.name} ${en.name}`}
              label={`${zh.name} ${en.name}`}
              onChange={(event): void => {
                const cloneVisitors = [...formValue.visitors]
                cloneVisitors[0].name = event.target.value
                setFormValue({
                  ...formValue,
                  visitors: cloneVisitors,
                })
              }}
              required
            />
          </Grid.Column>
          <Grid.Column mobile={8} computer={8}>
            <Form.Input
              name="vistorContactNumber"
              placeholder={`${zh.contactNumber} ${en.contactNumber}`}
              label={`${zh.contactNumber} ${en.contactNumber}`}
              onChange={(event): void => {
                const cloneVisitors = [...formValue.visitors]
                cloneVisitors[0].contactNumber = event.target.value
                setFormValue({
                  ...formValue,
                  visitors: cloneVisitors,
                })
              }}
              required
            />
          </Grid.Column>
        </Grid>

        <Grid>
          <Grid.Column mobile={8} computer={8}>
            <Form.Input
              name="numberOfVisitor"
              placeholder={`${zh.numberOfVisitor} ${en.numberOfVisitor}`}
              label={`${zh.numberOfVisitor} ${en.numberOfVisitor}`}
              onChange={(event): void => {
                setFormValue({
                  ...formValue,
                  numberOfVisitors: event.target.value,
                })
              }}
              required
            />
          </Grid.Column>
        </Grid>

        <Grid>
          <Grid.Column mobile={16} computer={8}>
            <Form.Input
              name="visitingStaffName"
              placeholder={`${zh.visitingStaffName} ${en.visitingStaffName}`}
              label={`${zh.visitingStaffName} ${en.visitingStaffName}`}
              onChange={(event): void =>
                setFormValue({
                  ...formValue,
                  visitingStaffName: event.target.value,
                })
              }
              required
            />
          </Grid.Column>

          <Grid.Column
            mobile={16}
            computer={locationFilter === locationOtherString ? 3 : 4}
          >
            <Form.Dropdown
              name="locationType"
              label={`${zh.location} ${en.location}`}
              placeholder={`${zh.defaultLocation} ${en.defaultLocation}`}
              onChange={locationTypeDropdownOnChange}
              options={locationTypeOptions}
              value={locationFilter}
              search
              selection
              required
              fluid
              selectOnBlur={false}
            />
          </Grid.Column>
          {locationFilter === locationOtherString ? (
            <Grid.Column mobile={16} computer={5}>
              <Form.Input
                name="locationOthers"
                label={`${zh.stationsAndOthers} ${en.stationsAndOthers}`}
                placeholder={locationOtherStatePlaceholder}
                value={locationOtherState}
                onChange={(event): void =>
                  setLocationOtherState(event.target.value)
                }
              />
              {isLocationEmpty && locationString === '' && (
                <Message negative>
                  {zh.locationInputWarningMessage}
                  {en.locationInputWarningMessage}
                </Message>
              )}
            </Grid.Column>
          ) : (
            <Grid.Column mobile={16} computer={4}>
              <LocationDropDown
                name="location"
                label=""
                placeholder={
                  locationFilter === ''
                    ? undefined
                    : `${zh.defaultLocation} ${en.defaultLocation}`
                }
                onChange={locationDropdownOnChange}
                options={locationOptions}
                value={locationState}
                search
                selection
                fluid
                selectOnBlur={false}
                disabled={locationFilter === ''}
              />
              {isLocationEmpty && locationString === '' && (
                <Message negative>
                  {zh.locationWarningMessage} {en.locationWarningMessage}
                </Message>
              )}
            </Grid.Column>
          )}

          <Grid.Column mobile={16} computer={8}>
            <StyledSemanticDatepicker
              type="basic"
              // @ts-ignore
              name="dateOfVisiting"
              datePickerOnly
              value={
                formValue.dateOfVisiting == null
                  ? undefined
                  : moment(formValue.dateOfVisiting).toDate()
              }
              label={`${zh.dateOfVisiting} ${en.dateOfVisiting}`}
              onChange={(event, data): void => {
                setFormValue({
                  ...formValue,
                  dateOfVisiting:
                    data.value == null
                      ? null
                      : moment(data.value as Date).format('YYYY-MM-DD'),
                })
              }}
              required
              filterDate={(date): boolean => {
                if (moment(date).isSame(moment(), 'day')) {
                  return true
                }

                // if Sat / Sun
                if (moment(date).day() === 0 || moment(date).day() === 6) {
                  return false
                }

                const todayWeekday = moment().day()
                // adding extra 2 day since Thu and Fri skipped Say / Sun
                const adjustedVisitingDay =
                  todayWeekday === 4 || todayWeekday === 5
                    ? maxVisitingDaysDiff + 2
                    : todayWeekday === 6
                    ? maxVisitingDaysDiff + 1
                    : maxVisitingDaysDiff

                return (
                  moment().isSameOrBefore(moment(date), 'd') &&
                  moment()
                    .add(adjustedVisitingDay, 'd')
                    .isSameOrAfter(moment(date), 'd')
                )
              }}
            />
          </Grid.Column>
        </Grid>

        <ConfirmDeclaration required>
          <Checkbox
            label={zh.declaration}
            onClick={(event, props): void =>
              setInformationDeclaration(props.checked ?? false)
            }
            required
          />
          <ConfirmDeclarationLabel>
            {zh.confirmDeclaration}
          </ConfirmDeclarationLabel>
          <ConfirmDeclarationLabel>
            <ConfirmDeclarationLabelText>
              {en.declaration}
            </ConfirmDeclarationLabelText>
            <ConfirmDeclarationLabelText>
              {en.confirmDeclaration}
            </ConfirmDeclarationLabelText>
          </ConfirmDeclarationLabel>
        </ConfirmDeclaration>

        <Button
          type="submit"
          loading={isSubmitting}
          color="blue"
          fluid
          disabled={!informationDeclared}
          size="huge"
          icon="send"
          content={`${zh.submit} ${en.submit}`}
        />
      </Form>
      <Dimmer
        active={errorMessage != null}
        onClickOutside={(): void => setErrorMessage(undefined)}
        page
      >
        <ErrorMessage
          error={!(errorMessage?.status === 403)}
          warning={errorMessage?.status === 403}
          size="big"
          icon={errorMessage?.status === 403 ? 'ban' : 'warning sign'}
          header={
            errorMessage?.status === 403
              ? `${zh.forbidTitle} ${en.forbidTitle}`
              : `${zh.submissionFailed} ${en.submissionFailed}`
          }
          content={
            errorMessage?.status === 403 ? (
              <>
                {zh.forbidMessage}
                <br />
                {en.forbidMessage}
                <br />
              </>
            ) : (
              <>
                {zh.submissionFailedDetail}
                <br />
                {en.submissionFailedDetail}
                <br />
                {`(Error = ${errorMessage?.status})`}
              </>
            )
          }
        />
      </Dimmer>
    </Root>
  )
}
